import { Unity, useUnityContext } from "react-unity-webgl";
import { useEffect, useState, Fragment, useRef } from "react";
import LiveChat from './LiveChat/LiveChat';
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setUsers, User } from "../store/usersOnline";
import { UpdateAvatarModal } from "./ReadyPlayerMe/UpdateAvatarModal";
import { setId, setPlayer, setSessionId, setUpdate } from "../store/appUser";
import { setWebsocket } from "../store/debug";
import LoadingPage from "./LoadingPage/LoadingPage";
import { Profile } from "./Profile";
import { PartiesUi } from "./PartiesUi";
import { globalIsHost } from "./InboundLinkRouting";

type Props = {
  id: string | undefined
}
export interface RouteParams extends Record<string, string | undefined> {
  id: string;
}
export const GlobalRefs = {
  sendMessage: null as ReactUnityEventParameter | null,

}

type UnityConfig = {
  readonly streamingAssetsUrl?: string;
};

type disconnect = {
  timestamp: string,
  playerId: string
}

export const UnityClient: React.FC<Props> = ({ id }) => {

  const colors = [
    "#00FF00", "#A078D2", "#64EAFF", "#E6E6FA", "#008B8B", "#90EE90", "#9400D3", "#87CEEB", "#006400", "#FFD700",
    "#DAA520", "#B8860B", "#BA55D3", "#9370DB", "#8A2BE2", "#4B0082", "#483D8B", "#6A5ACD", "#7B68EE", "#4682B4",
    "#4169E1", "#0000FF", "#1E90FF", "#00BFFF", "#5F9EA0", "#20B2AA", "#3CB371", "#2E8B57", "#006400", "#9ACD32",
    "#32CD32", "#00FF7F", "#00FA9A", "#66CDAA", "#8FBC8F", "#228B22", "#008000", "#808000", "#6B8E23", "#556B2F",
    "#DEB887", "#D2B48C", "#FFE4C4", "#FFDEAD", "#FFDAB9", "#FFE4B5", "#F0E68C", "#EEE8AA", "#FAFAD2", "#FFFFE0",
    "#FFFF00", "#FFD700", "#FFD700", "#DAA520", "#B8860B", "#FFD700", "#BA55D3", "#9370DB", "#8A2BE2", "#4B0082",
    "#483D8B", "#6A5ACD", "#7B68EE", "#4682B4", "#4169E1", "#0000FF", "#1E90FF", "#00BFFF", "#5F9EA0", "#20B2AA",
    "#3CB371", "#2E8B57", "#006400"
  ];

  const { index, moduleId } = useAppSelector(state => state.scenes)

  const isFull = process.env.REACT_APP_FULL === "true";
  const dispatch = useAppDispatch()
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { player, update } = useAppSelector(state => state.appUser)
  const { messageHistory } = useAppSelector(state => state.messages)
  const [unityComponent, setUnityComponent] = useState<JSX.Element | null>(null);
  const [showUnityClient, setShowUnityClient] = useState(true);
  const [websocketOpen, setWebsocketOpen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [room, setRoom] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false)
  const { users } = useAppSelector((state) => state.usersOnline)
  const usersRef = useRef(users)
  const updateRef = useRef(update)
  const messageHistoryRef = useRef(messageHistory)
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const showProfileRef = useRef(showProfile)
  const playerRef = useRef(player)
  const [unityInstance, setUnityInstance] = useState<any>(null);

  //Update refs
  useEffect(() => {
    usersRef.current = users
    updateRef.current = update
    messageHistoryRef.current = messageHistory
    showProfileRef.current = showProfile
    playerRef.current = player
  }, [users, update, messageHistory, showProfile, player])


  useEffect(() => {
    const handleUnityMessage = (event: MessageEvent) => {

      if (event.origin !== window.location.origin) return;

      if (event.data.type === "UNITY_INSTANCE_READY") {
        console.log("Unity instance is ready!");
        iFramePostMessage("ReactClientManager", "SetSTOMPConnectHeaders", JSON.stringify({
          authToken: playerRef.current.authToken,
          sceneId:moduleId
        }))
        setUnityInstance(event.data.unityInstance);
        iFramePostMessage("IFrameContentUpdate", "SetIsHost", globalIsHost.isHost)
        initialiseUnity()

      }
    };

    window.addEventListener("message", handleUnityMessage);

    return () => {
      window.removeEventListener("message", handleUnityMessage);
    };
  }, []);

  function initialiseUnity() {
    console.log("Initialising unity")
    let data = {
      displayName: playerRef.current.displayName,
      avatarUrl: playerRef.current.glbUrl,
      colour: playerRef.current.colour === '#fff' || playerRef.current.colour === null ? colors[Math.floor(Math.random() * colors.length)] : playerRef.current.colour
    }

    iFramePostMessage("ReactClientManager", "OnAvatarCreationCompleted", null)
      if (id === "bb9fd5bd-fd80-4524-8355-8c765d5510d8") {
        let stateToUpdate = {
          ...playerRef.current,
          glbUrl: "cba6e927-9de4-4e03-a190-df9262359f14",
          displayName: "Soledad"
        };
        dispatch(setPlayer(stateToUpdate))
        data.avatarUrl = "cba6e927-9de4-4e03-a190-df9262359f14"
        data.displayName = "Soledad"
      }
      console.log("UNITY DATA")
      console.log(data)


      iFramePostMessage("ReactClientManager", "OnAvatarCreationCompleted", null)
      setTimeout(() => {
        iFramePostMessage("ReactClientManager", "SetLocalAvatar", JSON.stringify(data))
      }, 4000)
  }

  useEffect(() => {
    window.sendMessage = iFramePostMessage
  });

  function handleProfileClick(){
   
    console.log("Profile button clicked");
    if (
      id !== "bb9fd5bd-fd80-4524-8355-8c765d5510d8" &&
      !player.glbUrl?.endsWith(".vrm")
    ) {
      setShowProfile(!showProfileRef.current);
    }
  };

  // handle Subscriptions
  function subscribeToUpdateAndDisconnect(room: string) {
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/topic/update-avatar/" + room, "STOMP_AVATAR_UPDATE")
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/topic/disconnect-user/" + room, "STOMP_DISCONNECT")
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/topic/chat", "STOMP_GLOBAL_CHAT")
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/topic/chat/" + room, "STOMP_ROOM_CHAT")
    iFramePostMessage("ReactClientManager", "SubscribeToSTOMPTopic", "/user/queue/party", "STOMP_PARTY")
  }

  // update avatars online
  function updateAvatars(avatars: User[]) {
    console.log(avatars)

    let currentUsers = [...usersRef.current]
    console.log(currentUsers)
    avatars.forEach((user: User) => {
      const existingUserIndex = currentUsers.findIndex(existingUser => existingUser.playerId === user.playerId);

      if (existingUserIndex === -1) {
        currentUsers.push(user);
      } else {
        currentUsers[existingUserIndex] = user;
      }

      if (user.avatarUrl.trim() === player.glbUrl?.trim() && 
        user.displayName.trim().toLowerCase() === player.displayName?.trim().toLowerCase() && 
        user.colour === player.colour) {
        console.log("Setting PlayerId: " + user.playerId);
      dispatch(setId(user.playerId));
    }
    

    })
    dispatch(setUsers(currentUsers))
    dispatch(setUpdate(!updateRef.current))
  }

  // handle Disconnects
  function disconnectAvatars(avatars: disconnect[]) {
    let currentUsers = [...usersRef.current]
    const disconnectIds = new Set(avatars.map(avatar => avatar.playerId));
    currentUsers = currentUsers.filter(user => !disconnectIds.has(user.playerId));
    dispatch(setUsers(currentUsers))
  }

    // post Messages to the iFrame
    function iFramePostMessage(to: string, type: string, data: any | null, stomptype?:string) {
      const iframe = document.getElementById('unityIframe') as HTMLIFrameElement | null;
  
      if (iframe && iframe.contentWindow) {
  
        const msg = { to: to, type: type, data: data, stomptype: stomptype };
        iframe.contentWindow.postMessage(msg, '*');
      } else {
        console.error('Iframe not found or contentWindow is null');
      }
    }
  
    // Recieve Messages from the iFrame
    const handleIframeMessage = (event: MessageEvent) => {
      const data = event.data;
      console.log('Message received from iframe:', data);
      switch (data.type) {
        case "STOMP_INITIALISE":
          console.log(data.frame)
          setRoom(data.frame.body.room)
          subscribeToUpdateAndDisconnect(data.frame.body.room)
          updateAvatars(data.frame.body.avatarDefinitions)
          dispatch(setWebsocket("Connected"))
          dispatch(setSessionId(data.frame.body.sessionId))
          break;
        case "STOMP_DISCONNECT":
          disconnectAvatars(data.frame.body)
          break;
        case "STOMP_AVATAR_UPDATE":
          updateAvatars(data.frame.body)
          break;
        case "PROFILE_CLICKED":
          handleProfileClick()
          break;
        default:
          break;
      }
    };
  
    // setup listener for messages
    useEffect(() => {
      window.addEventListener('message', handleIframeMessage);
  
      return () => {
        window.removeEventListener('message', handleIframeMessage);
      };
    }, []);

  return (
    <Fragment>
    {showUnityClient && (
        <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
          <iframe
            onLoad={() => setIsLoaded(true)}
            ref={iframeRef}
            src={index + '?show_call_ui=true'}
            width="100vw"
            height="100vh"
            title="Example Website"
            id="unityIframe"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 0,
              pointerEvents: 'auto'
            }}
          />

          {isLoaded && (<PartiesUi frame={iframeRef.current}/>)}
          {room && (
            <div>
              <Profile sendMessage={iFramePostMessage}/>
              <LiveChat room={room} iFramePostMessage={iFramePostMessage}/>
            </div>
          )}
          <UpdateAvatarModal
            setOpen={setShowProfile}
            open={showProfile}
            sendMessage={iFramePostMessage}
          />
        </div>
      )}
  </Fragment>
  
  )
}