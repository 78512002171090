import { createSlice} from "@reduxjs/toolkit";

interface SceneState {
    name: string,
    moduleId: string,
    index:string | undefined
    sceneTiles: SceneTiles[]
}


export interface SceneTiles {
    name: string,
    moduleId: string,
    image: string,
    index:string | undefined
}

const initialState: SceneState = {
    name: "",
    moduleId: "",
    index:process.env.REACT_APP_UNITY_BUILD_FILE_INDEX_DOG_LIBRE,
    sceneTiles: [
        {
            name: "DoglLife",
            moduleId: "",
            image: "",
            index: process.env.REACT_APP_UNITY_BUILD_FILE_INDEX_DOG_LIBRE
        }
    ]
}

export const sceneStateSlice = createSlice({
    name:"scenes",
    initialState,
    reducers: {
        setSceneState: (state, action) => {
            state = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setModuleId: (state, action) => {
            state.moduleId = action.payload
        },
        setIndex: (state, action) => {
            state.index = action.payload
        }
    }
});

export const { setName, setModuleId,setSceneState, setIndex } = sceneStateSlice.actions;

export default sceneStateSlice.reducer;