import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { EditType, Template } from './RPMTypes';
import { sethideUseCurrentButton } from '../../../store/appUser';

const TemplateSelector: React.FC<{
  templates: { id: string; imageUrl: string }[];
  loggedIntoRPM: boolean;
  closeModal: Function,
  assignMyAvatar: (template: any) => void;
  createNewAvatar: Function,
  assignTemplate: Function,
  applyEditsToAccountTemplate: Function
  getMyAvatars: Function
}> = ({ templates, loggedIntoRPM, closeModal, assignMyAvatar, createNewAvatar, assignTemplate, applyEditsToAccountTemplate, getMyAvatars }) => {
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const { rpmBearerToken, rpmId, hideUseCurrentButton, assignedTemplate,edit } = useAppSelector(state => state.appUser)
  const dispatch = useAppDispatch()

  const handleNext = () => {
    setLoading(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % templates.length);
  };

  const handlePrev = () => {
    setLoading(true);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + templates.length) % templates.length);
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  const currentTemplate = templates[currentIndex];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '50%',
        margin: '2% auto',
        borderRadius: '25px',
        color: 'white',
        backgroundColor: 'rgb(193, 173, 144)',
        position: 'relative',
        overflow: 'visible',
      }}
    >
      <div
        className="paw-container"
        style={{
          position: 'absolute',
          top: '-5%',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          gap: '10px',
        }}
      >
        <img height={20} src="/images/paw.png" alt="left paw" />
        <h1
          style={{
            margin: 0,
            fontSize: '1.3rem',
            padding: '5px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {loggedIntoRPM ? 'Select Your Avatar' : 'Select a Template'}
        </h1>
        <img height={20} src="/images/paw.png" alt="right paw" />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgb(235, 235, 235)',
          margin: '50px 10px 10px 10px',
          width: '90%',
          height: '70%',
          padding: '15px',
          borderRadius: '50px',
        }}
      >
        <div
          style={{
            marginBottom: '20px',
            textAlign: 'center',
            position: 'relative',
            width: '70%',
            height: '70%'
          }}
        >
          {loading && (
            <><div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 7,
                flexDirection: 'column'
              }}
            >
              <CircularProgress color="error" />
              <span style={{ color: 'black' }}>Loading...</span>
            </div><img
                src={`/images/none.png`}
                alt="Avatar Template"
                style={{
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }} /></>
          )}
          <img
            src={`${currentTemplate.imageUrl}?size=256&quality=30`}
            alt="Avatar Template"
            onClick={() => {
              dispatch(sethideUseCurrentButton(true))
              assignMyAvatar(currentTemplate);
              getMyAvatars(rpmBearerToken, rpmId)
              closeModal(false)
            }}
            onLoad={handleImageLoad}
            style={{
              display: loading ? 'none' : 'block',
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              cursor: 'pointer'
            }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <button
            onClick={handlePrev}
            style={{
              background: 'none',
              border: '1px solid black',
              color: 'black',
              padding: '10px',
              cursor: 'pointer',
            }}
          >
            &lt; Prev
          </button>
          <button
            onClick={handleNext}
            style={{
              background: 'none',
              border: '1px solid black',
              color: 'black',
              padding: '10px',
              cursor: 'pointer',
            }}
          >
            Next &gt;
          </button>
        </div>
      </div>
      <div style={{ marginBottom: '10px', display: 'flex', gap: 2 }}>
        <button className="rpm-button" style={{fontSize: '1.5vh'}} onClick={() => {
          dispatch(sethideUseCurrentButton(true))
          createNewAvatar(rpmBearerToken)
          getMyAvatars(rpmBearerToken, rpmId)
          closeModal(false)
        }} >Create New</button>
       { !hideUseCurrentButton && ( <button
         className="rpm-button"
         style={{fontSize: '1.5vh'}}
          onClick={() => {
            dispatch(sethideUseCurrentButton(true))
            assignTemplate(assignedTemplate, rpmBearerToken)
            closeModal(false)
            setTimeout(() => {
              applyEditsToAccountTemplate(edit, rpmBearerToken)
              getMyAvatars(rpmBearerToken, rpmId)
            }, 2000)
          }}
        >Use Current</button>)}
      </div>

    </div>
  )

};

export default TemplateSelector;

