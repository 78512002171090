import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useAppSelector } from "../../store/hooks";
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";
import { setPlayer } from "../../store/appUser";
import { useNavigate } from "react-router";
import 'animate.css';
import { Header } from "../MiscComponents/Header";
import { Footer } from "../MiscComponents/Footer";

export interface WindowSize {
    width: number,
    height: number
}

type Props = {
    redirect: Function
}


export const AvatarPage: React.FC<Props> = ({ redirect }) => {

    const history = useNavigate();
    let dispatch = useDispatch();
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [hasCookie, setHasCookie] = useState<boolean>(false)
    const [prompt, setprompt] = useState<string>("")
    const [showRpm, setShowRpm] = useState(false)
    const [selectedMale, setSelectedMale] = useState(false)
    const [selectedFemale, setSelectedFemale] = useState(false)
    const { player } = useAppSelector((state) => state.appUser)
    const circleScalingFactor = Math.min(windowSize.width / 1800, windowSize.height / 1800);


    useEffect(() => {
        if (player.glbUrl) {
            handleShowAvatar(player.glbUrl)
            setHasCookie(true)
        }
    }, [player.glbUrl])

    // useEffect(() => {
    //     let username = Cookies.get("username");
    //     if(username){
    //         dispatch(setAvatarName(username))
    //     }
    // },[])

    useEffect(() => {
        console.log(player)
    }, [player])

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function handleShowAvatar(glb: string) {
        let stateToUpdate = {
            ...player,
            glbUrl: glb,
        };
        dispatch(setPlayer(stateToUpdate))

    }

    function handleAvatarName(name: string) {
        let clean = cleanMessage(name)
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        if(urlRegex.test(clean)){
            clean = clean.replace(urlRegex, "*")
        }
        let stateToUpdate = {
            ...player,
            displayName: clean,
        };
        dispatch(setPlayer(stateToUpdate))
        Cookies.set("username", clean)
    }

    function handleJumpIn() {
        if (player.glbUrl !== null) {
            redirect(true)
        } else {
            setprompt("prompt")
            setTimeout(() => {
                setprompt("")
            }, 2000)
        }
    }


    return (
        <><Header/>
            <div style={{
                position: 'absolute',
                bottom: '10%',
                display: 'flex',
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '80vh',
                width: '100%'
            }}>
        
                <div className="avatar-input-area2 animate__animated animate__bounceInLeft" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', 
                    zIndex: 1,
                    height: '8vh'
                }}>
                    <div className="paw-container" style={{position: 'relative', top: '-60%'}}>
                        <img height={20} src="/images/paw.png" alt="left paw" />
                        <h3>Enter Your Name</h3>
                        <img height={20} src="/images/paw.png" alt="right paw" />
                    </div>
                    <div>
                        <input
                            className="custom-input"
                            style={{
                                width: '80%',
                                textAlign: 'center'
                            }}
                            placeholder="Name"
                            value={player.displayName ? player.displayName : ""}
                            onChange={e => handleAvatarName(e.target.value)} />
                    </div>
                    <br />
                </div>
        
                <div className="avatar-input-area1 animate__animated animate__bounceInRight" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', 
                    zIndex: 1,
                    height: '23vh'
                }}>
                    <div className="paw-container" style={{position: 'relative', top: '-25%'}}>
                        <img height={20} src="/images/paw.png" alt="left paw" />
                        <h3>Choose your Avatar</h3>
                        <img height={20} src="/images/paw.png" alt="right paw" />
                    </div>
                    <div className="avatar-selection">
                            <img 
                                src="/images/Creator.png" 
                                alt="creator-img" 
                                style={{ marginRight: '20px' }}
                            />
                            <div className="avatar-details">
                                <div>
                                    <p style={{ fontSize: '2vh', marginBottom: '10px' }}>Click Begin To Create your Avatar Design</p>
                                    <button
                                        className='button-19'
                                        onClick={() => history("/mv/editor")}
                                        disabled={!(!!player.displayName)}
                                    >
                                        {!!player.displayName ? "Begin" : "Choose Name"}
                                    </button>
                                </div>
                               
                            </div>
                        </div>

                </div>
            </div>
            <Footer/>
            </>
    )
}