import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import { apiRequest } from './APICalls';
import { EditType, LoginResponse, RefreshResponse, Template } from './RPMTypes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setLoggedIntoRPM, setPlayer, setRpmBearerToken, setRpmId } from '../../../store/appUser';
import { useEffect } from 'react';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export type StyleType = {
  position: string;
  top: string;
  left: string;
  transform: string;
  width: string;
  boxShadow: number;
  p: number;
};

export const style: StyleType = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20%',
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean,
  setOpen: Function,
  showAccountModal: Function,
  getMyAvatars: Function,
}
export const UserLogin: React.FC<Props> = ({ open, setOpen, showAccountModal, getMyAvatars}) => {

  const handleClose = () => setOpen(false);
  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [sendText, setSendText] = React.useState("");
  const [refreshTokenSaved, setRefreshTokenSaved] = React.useState<undefined | string>(undefined);
  const { player } = useAppSelector(state => state.appUser)
  const dispatch = useAppDispatch();
  const playerRef = React.useRef(player)

  useEffect(() => {
    playerRef.current = player
  },[player])

  async function startAuth() {

    setSendText("Email Sent, please check Junk Mail if not Recieved!")
    setTimeout(() => {
      setSendText("")
    }, 6000)
    const response = await apiRequest(
      `https://testapp-uy5uxo.readyplayer.me/api/auth/start`,
      {
        method: 'POST',
        body: {
          data: {
            email: email,
            authType: "code",
          }
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "sk_live_ukCGG9Uw2rD1mCJAlV0Yk1JDyozjAIgvTohC",
        },
      }
    );

    if (response.error) {
      console.error('API Error:', response.error);
    } else {
      console.log('API Data:', response.data);


      handleClose();
    }
  }

  async function loginUser() {
    const response = await apiRequest<LoginResponse>(
      `https://testapp-uy5uxo.readyplayer.me/api/auth/login`,
      {
        method: 'POST',
        body: {
          data: {
            code: code
          }
        },
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "sk_live_ukCGG9Uw2rD1mCJAlV0Yk1JDyozjAIgvTohC",
        },
      }
    );

    if (response.error) {
      console.error('API Error:', response.error);
    } else {
      console.log('API Data:', response.data);
      dispatch(setLoggedIntoRPM(true))
      dispatch(setRpmBearerToken(response.data?.data.token));
      dispatch(setRpmId(response.data?.data._id))
      setInterval(() => refreshToken(response.data?.data.token, response.data?.data.refreshToken), 14 * 60 * 1000);
      handleClose();
      showAccountModal(true)
      getMyAvatars(response.data?.data.token, response.data?.data._id)
      let data = {...playerRef.current, createdByRpmAccount: true}
      dispatch(setPlayer(data))
    }


  }

  async function refreshToken(token: string | undefined, refreshToken: string | undefined) {
    if (token !== undefined && refreshToken !== undefined) {
      const response = await apiRequest<RefreshResponse>(
        `https://api.readyplayer.me/v1/auth/refresh`,
        {
          method: 'POST',
          body: {
            token: token,
            refreshToken: refreshTokenSaved ? refreshTokenSaved : refreshToken
          },
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "sk_live_ukCGG9Uw2rD1mCJAlV0Yk1JDyozjAIgvTohC",
            "X-APP-ID": "6620f37fc062d6de28094e5d",
          },
        }
      );

      if (response.error) {
        console.error('API Error:', response.error);
      } else {
        console.log('API Data:', response.data);
        dispatch(setRpmBearerToken(response.data?.token));
        setRefreshTokenSaved(response.data?.refreshToken);
      }
    }

  }

  return (
    <div >
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
        sx={{
          zIndex: 10000000,
        }}
      >
        <Fade in={open}>
          <Box component="div" className='RPMLogin' sx={style}>
            <h3>Login</h3>
            <p>Please Enter your Email to Recieve the Verification Code: </p>
            <p style={{ color: 'green' }}>{sendText}</p>
            <input className='custom-input' style={{ marginTop: '20px', width: '100%' }} type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
            <button
              className='button-19'
              onClick={() => startAuth()}
            >submit</button>
            <p>Enter Code here: </p>
            <input className='custom-input' style={{ marginTop: '20px', width: '100%' }} type='text' value={code} onChange={(e) => setCode(e.target.value)} />
            <button
              className='button-19'
              onClick={() => loginUser()}
            >submit</button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
