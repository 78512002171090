import { createSlice} from "@reduxjs/toolkit";
import { StaticReadUsage } from "three";
import { EditType, Template } from "../components/ReadyPlayerMe/ReadyPlayerMeComponents/RPMTypes";

interface userMessage {
    guid: string,
    mesg: string, 
    timestamp: string
}

export interface user {
    displayName: string | null,
    glbUrl: string | null,
    avatarProfilePic: string | null,
    disabled: boolean,
    hashedEmail: string,
    created: number,
    createdOffset: string,
    createdZone: string,
    authToken: string,
    playerId: string,
    colour: string,
    createdByRpmAccount: boolean
}

interface AppUserState {
    chatHandle: string,
    userMessages: userMessage[],
    player: user,
    id: string,
    update: boolean,
    sessionId: string,
    isInParty: boolean,
    rpmId: string | null,
    rpmBearerToken: string,
    avatarGender: "male" | "female",
    avatarId: string 
    loggedIntoRPM: boolean,
    hideUseCurrentButton: boolean,
    assignedTemplate: Template  | null,
    edit: EditType | null,
    needsLoginFlag: boolean,
    cookieDoesntMatchFlag: boolean
}

const initialState: AppUserState = {
    chatHandle: "",
    userMessages: [],
    player: {
        displayName: "Guest",
        glbUrl: "https://models.readyplayer.me/6769814c4f268917b376febc.glb",
        avatarProfilePic: null,
        disabled: false,
        hashedEmail: "",
        created: -1,
        createdOffset: "",
        createdZone: "",
        authToken: "",
        playerId: "",
        colour: "#fff",
        createdByRpmAccount: false,
    },
    id: "",
    update: false,
    sessionId: "",
    isInParty: false,
    rpmId: null,
    rpmBearerToken: "",
    avatarGender: "male",
    avatarId: "",
    loggedIntoRPM: false,
    hideUseCurrentButton: false,
    assignedTemplate: null,
    edit: null,
    needsLoginFlag: false,
    cookieDoesntMatchFlag: false
}
export const appUserSlice = createSlice({
    name:"appUser",
    initialState,
    reducers: {
        setUserMessages: (state, action) => {
            state.userMessages = action.payload
        },
        setChatHandle: (state, action) => {
            state.chatHandle = action.payload
        },
        setPlayer: (state, action) => {
            state.player = action.payload
        },
        setId: (state, action) => {
            state.id = action.payload
        },
        setUpdate: (state, action) => {
            state.update = action.payload
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload
        },
        setIsInParty: (state, action) => {
            state.isInParty = action.payload
        },
        setRpmId: (state, action) => {
            state.rpmId = action.payload
        },
        setRpmBearerToken: (state, action) => {
            state.rpmBearerToken = action.payload
        },
        setAvatarGender: (state, action) => {
            state.avatarGender = action.payload
        },
        setAvatarId: (state, action) => {
            state.avatarId = action.payload
        },
        setLoggedIntoRPM: (state, action) => {
            state.loggedIntoRPM = action.payload
        },
        sethideUseCurrentButton: (state, action) => {
            state.hideUseCurrentButton = action.payload
        },
        setAssignedTemplate: (state, action) => {
            state.assignedTemplate = action.payload
        },
        setEdit: (state, action) => {
            state.edit = action.payload
        },
        setNeedsLoginFlag: (state, action) => {
            state.needsLoginFlag = action.payload
        },
        setCookieDoesntMatchFlag: (state, action) => {
            state.cookieDoesntMatchFlag = action.payload
        }
    }
});

export const { 
    setId, 
    setUserMessages,
    setChatHandle, 
    setPlayer, 
    setUpdate, 
    setSessionId, 
    setIsInParty, 
    setRpmId, 
    setRpmBearerToken, 
    setAvatarGender, 
    setAvatarId, 
    setLoggedIntoRPM,
    sethideUseCurrentButton,
    setAssignedTemplate, 
    setEdit,
    setNeedsLoginFlag,
    setCookieDoesntMatchFlag
 } = appUserSlice.actions;

export default appUserSlice.reducer;