import { useEffect, useState } from "react"
import { RouteParams, UnityClient } from "./UnityClient";
import { AvatarPage } from "./AvatarPage/AvatarPage";
import { useParams } from "react-router";
import { setAvatarGender, setAvatarId, setCookieDoesntMatchFlag, setNeedsLoginFlag, setPlayer, setRpmBearerToken, setRpmId, user } from "../store/appUser";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import Cookies from "js-cookie";

export const globalIsHost = {
    isHost: false
} 


export const InboundLinkRouting: React.FC = () => {

    const {id} = useParams<RouteParams>();
    const { player } = useAppSelector(state => state.appUser)
    const [hasCookie, setHasCookie] = useState(false);
    const bypassId = "demo";
    const hostId = "b2124128-a607-487f-b9de-33b03750cab9";
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(id !== bypassId){
            fetchData(window.location.origin + "/user-profile")
            .then(data => {
                console.log('Data received:', data);

                dispatch(setPlayer(data))
                setFlags(data)
                if (data.glbUrl === null && Cookies.get("AvatarGlb") === undefined) {
                    setHasCookie(false)
                }
                if (data.glbUrl) {
                    let modelId = data.glbUrl?.split('/').pop()?.split('.')[0] ?? null;
                    window.avatarId = modelId
                }
            })
            .catch(error => {
                setFlags(player)
                console.error('Error:', error);
            });
        }
    }, []);

    function setFlags(data: user){
        // if their isnt a glb or cookie set flags to false
        if (data.glbUrl === null && Cookies.get("AvatarGlb") === undefined) {
            dispatch(setCookieDoesntMatchFlag(false))
            dispatch(setNeedsLoginFlag(false))
            setHasCookie(false)
        } else {
            // otherwise set tokens from cookies for loading avatar and getting assets
            setHasCookie(false)
            dispatch(setRpmId(Cookies.get("rpmId")))
            dispatch(setRpmBearerToken(Cookies.get("rpmToken")))
            
            // if the avatar was created with rpm account make flag true to show alert
            if(player.createdByRpmAccount){
                dispatch(setNeedsLoginFlag(true))
            } else if (player.glbUrl !== Cookies.get("AvatarGlb")){
                // otherwise if the cookie and avatar dont match and created with rpm is false set cookies dont match flag
                dispatch(setCookieDoesntMatchFlag(player.glbUrl !== null ? true : false))
            }
            
        }

        
        if (data.glbUrl){
            dispatch(setAvatarId(data.glbUrl.replace(".glb", "").split("/").pop()))
        }
    }

    async function fetchData(url: string): Promise<any> {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }
    
    if (id === hostId) {
         console.log("setting Global Host")
        globalIsHost.isHost = true
    }

    // probably wont need this as is Cookie Based
    // useEffect(() => {
    //     let avatarUrl = Cookies.get("avatarUrl");
    //     let username = Cookies.get("username");

    //     if (avatarUrl === undefined || username === undefined) {
    //         setHasCookie(false);
    //     }
    // }, []);

    if (id === bypassId) {
        return <UnityClient id={id} />;
    }

    return(
        <div className="App">
            
            {
                hasCookie ? <UnityClient id={id}/> : <AvatarPage redirect={setHasCookie}/>
            }
        </div>
    )
}