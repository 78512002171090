import * as React from 'react';
import Box from '@mui/material/Box';
import { useSpring, animated } from '@react-spring/web';
import { useAppDispatch } from '../../store/hooks';
import { ReadyPlayerMeCustom } from './ReadPlayerMeCustom';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export type StyleType = {
  position: string;
  top: string;
  left: string;
  transform: string;
  width: string;
  boxShadow: number;
  p: number;
};

export const style: StyleType = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  boxShadow: 24,
  p: 4,
};

type Props = {
  open: boolean,
  setOpen: Function,
  sendMessage: Function
}
export const UpdateAvatarModal: React.FC<Props> = ({ open, setOpen, sendMessage }) => {

  const handleClose = () => setOpen(false);
  const dispatch = useAppDispatch()

  return (
    <div style={{ display: open ? "" : "none", zIndex: 5 }}>
      <Box component="div" className='profile-modal' sx={style}>
        <div className="paw-container-backpack" style={{ transform: 'translateY(45px)' }}>
          <img height={20} src="/images/paw.png" alt="left paw" />
          <h3 style={{ margin: 0, fontSize: '25px' }}>Backpack</h3>
          <img height={20} src="/images/paw.png" alt="right paw" />
        </div>
        <ReadyPlayerMeCustom className='rpm-container-modal' isModal={true} close={setOpen} sendMessage={sendMessage} />
      </Box>
    </div>
  );
}